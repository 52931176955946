import React, { useState } from 'react';
import './BannerOne.scss';

const BannerOne = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [recordingLink, setRecordingLink] = useState('');

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    whatsapp: '',
    recordingLink: '',
    interest: ''
  });

  const validateFields = () => {
    let isValid = true;
    let newErrors = {
      name: '',
      email: '',
      whatsapp: '',
      interest: ''
    };

    if (!name) {
      newErrors.name = 'O nome é obrigatório.';
      isValid = false;
    }

    if (!email) {
      newErrors.email = 'O email é obrigatório.';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Por favor, insira um email válido.';
      isValid = false;
    }

    if (!whatsapp) {
      newErrors.whatsapp = 'O WhatsApp é obrigatório.';
      isValid = false;
    } else if (!/^\+?[1-9]\d{1,14}$/.test(whatsapp)) {
      newErrors.whatsapp = 'Por favor, insira um número de WhatsApp válido.';
      isValid = false;
    }

    if (!recordingLink) {
      newErrors.recordingLink = 'O link da gravação é obrigatório.';
      isValid = false;
    } else if (!/^https?:\/\//.test(recordingLink)) {
      newErrors.recordingLink = 'O link da gravação deve começar com http:// ou https://';
      isValid = false;
    }

    if (!selectedOption) {
      newErrors.dropdown = 'Por favor, selecione uma opção.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    setLoading(true);
    const formSubmission = new FormData();
    formSubmission.append('name', name)
    formSubmission.append('email', email)
    formSubmission.append('phone', whatsapp)
    formSubmission.append('presentation_link', recordingLink)
    formSubmission.append('form_name', "Matriculas Abertas")

    // Fetch request to PHP handler
    fetch('https://midasacademy.art/form-handler.php', {
      method: 'POST',
      body: formSubmission
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Inscrição realizada com sucesso!');
        } else {
          alert(data.error || 'Ocorreu um erro');
        }
        setLoading(false);
        setName('');
        setEmail('');
        setWhatsapp('');
        setRecordingLink('');
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Erro de conexão. Por favor, tente novamente.');
        setLoading(false);
        setName('');
        setEmail('');
        setWhatsapp('');
        setRecordingLink('');
      });
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: '',
      }));
    }
  }

  const [selectedOption, setSelectedOption] = useState("Matricula");
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };


  return (
    <>
      <div className='BannerOne'>
        <div className="form-box-desk">
          <div className="form-container">
            <div className="form">
              <h3>Inscreva-se</h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Nome</label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    placeholder="Digite seu nome"
                    value={name}
                    disabled={loading}
                    onChange={(e) => {
                      setName(e.target.value);
                      handleInputChange(e, 'name');
                    }}
                  />
                  {errors.name && <small className="text-danger">{errors.name}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    placeholder="Digite seu email"
                    value={email}
                    disabled={loading}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      handleInputChange(e, 'email');
                    }}
                  />
                  {errors.email && <small className="text-danger">{errors.email}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="whatsapp">WhatsApp</label>
                  <input
                    type="text"
                    id="whatsapp"
                    className="form-control"
                    placeholder="Digite seu WhatsApp"
                    value={whatsapp}
                    disabled={loading}
                    onChange={(e) => {
                      setWhatsapp(e.target.value);
                      handleInputChange(e, 'whatsapp');
                    }}
                  />
                  {errors.whatsapp && <small className="text-danger">{errors.whatsapp}</small>}
                </div>

                <div className="form-group">
                  <label htmlFor="recordingLink">Link da Gravação</label>
                  <input
                    type="text"
                    id="recordingLink"
                    className="form-control"
                    placeholder="Cole aqui o link de sua gravação"
                    value={recordingLink}
                    disabled={loading}
                    onChange={(e) => {
                      setRecordingLink(e.target.value);
                      handleInputChange(e, 'recordingLink');
                    }}
                  />
                  {errors.recordingLink && <small className="text-danger">{errors.recordingLink}</small>}
                </div>
                <div className="form-group">
                  <label htmlFor="dropdown">Interesse</label>
                  <div className="dropdown">
                    <div className="dropdown-title" onClick={toggleDropdown}>
                      {selectedOption || "Selecione uma opção"} <span className="arrow">{isOpen ? "▲" : "▼"}</span>
                    </div>
                    {isOpen && (
                      <ul className="dropdown-options">
                        <li onClick={() => handleOptionClick("Matricula")}>Matrícula</li>
                        <li onClick={() => handleOptionClick("Bolsa")}>Bolsa</li>
                      </ul>
                    )}
                  </div>
                  {errors.dropdown && <small className="text-danger">{errors.dropdown}</small>}
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary" disabled={loading}>
                    {loading ? 'Carregando...' : 'Enviar'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="form-box-open">
        <div className="form-container">
          <div className="form">
            <h3>Inscreva-se</h3>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Nome</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder="Digite seu nome"
                  value={name}
                  disabled={loading}
                  onChange={(e) => {
                    setName(e.target.value);
                    handleInputChange(e, 'name');
                  }}
                />
                {errors.name && <small className="text-danger">{errors.name}</small>}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Digite seu email"
                  value={email}
                  disabled={loading}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    handleInputChange(e, 'email');
                  }}
                />
                {errors.email && <small className="text-danger">{errors.email}</small>}
              </div>

              <div className="form-group">
                <label htmlFor="whatsapp">WhatsApp</label>
                <input
                  type="text"
                  id="whatsapp"
                  className="form-control"
                  placeholder="Digite seu WhatsApp"
                  value={whatsapp}
                  disabled={loading}
                  onChange={(e) => {
                    setWhatsapp(e.target.value);
                    handleInputChange(e, 'whatsapp');
                  }}
                />
                {errors.whatsapp && <small className="text-danger">{errors.whatsapp}</small>}
              </div>

              <div className="form-group">
                <label htmlFor="recordingLink">Link da Gravação</label>
                <input
                  type="text"
                  id="recordingLink"
                  className="form-control"
                  placeholder="Cole aqui o link de sua gravação"
                  value={recordingLink}
                  disabled={loading}
                  onChange={(e) => {
                    setRecordingLink(e.target.value);
                    handleInputChange(e, 'recordingLink');
                  }}
                />
                {errors.recordingLink && <small className="text-danger">{errors.recordingLink}</small>}
              </div>
              <div className="form-group">
                <label htmlFor="dropdown">Interesse</label>
                <div className="dropdown">
                  <div className="dropdown-title" onClick={toggleDropdown}>
                    {selectedOption || "Selecione uma opção"} <span className="arrow">{isOpen ? "▲" : "▼"}</span>
                  </div>
                  {isOpen && (
                    <ul className="dropdown-options">
                      <li onClick={() => handleOptionClick("Matrícula")}>Matrícula</li>
                      <li onClick={() => handleOptionClick("Bolsa")}>Bolsa</li>
                    </ul>
                  )}
                </div>
                {errors.dropdown && <small className="text-danger">{errors.dropdown}</small>}
              </div>

              <div className="form-group">
                <button type="submit" className="btn btn-primary" disabled={loading}>
                  {loading ? 'Carregando...' : 'Enviar'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default BannerOne
